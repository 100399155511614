import { Route, Routes } from 'react-router-dom';
import About from './About/About';
import Contact from './Contact/Contact';
import Header from './Header/Header';
import Home from './Home/Home';
import classes from './Main.module.css';

const Main = () => (
  <div className={classes.container}>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </div>
);

export default Main;
