import { GiBookCover } from 'react-icons/gi';
import { MdWork } from 'react-icons/md';
import classes from './About.module.css';

const About = () => (
  <div className={classes.container}>
    <div className={[classes.flex, classes.exp].join(' ')}>
      <div className={[classes.flex, classes.box].join(' ')}>
        <MdWork className={classes.icon} />
        <div className={classes.desc}>
          <p>2021</p>
          <p>Intern</p>
          <p>Abir Automation & Engineering Ltd.</p>
        </div>
      </div>
      <div className={[classes.flex, classes.box].join(' ')}>
        <MdWork className={classes.icon} />
        <div className={classes.desc}>
          <p>2019</p>
          <p>Industrial Attatchment</p>
          <p>Shiddhirgonj 2x120MW Peaking Power Plant,EGCB.</p>
        </div>
      </div>
    </div>
    <div className={[classes.flex, classes.edu].join(' ')}>
      <div className={[classes.flex, classes.box].join(' ')}>
        <GiBookCover className={classes.icon} />
        <div className={classes.desc}>
          <p>2021</p>
          <p>B.Sc. in EEE</p>
          <p>
            Bangladesh Army University Of Engineering & Techonology,Qadirabad,
            Dayarampur .
          </p>
        </div>
      </div>
      <div className={[classes.flex, classes.box].join(' ')}>
        <GiBookCover className={classes.icon} />
        <div className={classes.desc}>
          <p>2016</p>
          <p>Higher Secondary School Certificate</p>
          <p>Bogura Cantonment Public School & College, Bogura Cantonment .</p>
        </div>
      </div>
      <div className={[classes.flex, classes.box].join(' ')}>
        <GiBookCover className={classes.icon} />
        <div className={classes.desc}>
          <p>2014</p>
          <p>Secondary School Certificate</p>
          <p>Bogura Cantonment Board High School, Bogura Cantonment .</p>
        </div>
      </div>
    </div>
  </div>
);

export default About;
