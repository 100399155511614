import emailjs from '@emailjs/browser';
import { useRef } from 'react';
// prettier-ignore
import {
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaTelegramPlane,
  FaTwitter
} from 'react-icons/fa';
import classes from './Contact.module.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE,
        process.env.REACT_APP_TEMPLATE,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert('email sent');
        },
        (error) => {
          alert(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className={classes.container}>
      <div className={classes.social}>
        <a href="https://t.me/rokusenpaii">
          <FaTelegramPlane />
        </a>
        <a href="https://www.facebook.com/rokusenpaii">
          <FaFacebookF />
        </a>
        <a href="https://twitter.com/_rokusenpai_">
          <FaTwitter />
        </a>
        <a href="https://www.instagram.com/_rokusenpai_/">
          <FaInstagram />
        </a>
        <a href="https://github.com/rokusenpaii">
          <FaGithub />
        </a>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="user_name" placeholder="Name" />
        <input type="text" name="subject" placeholder="Subject" />
        <input type="email" name="user_email" placeholder="Email" />
        <textarea name="message" placeholder="Message" rows="5" cols="33" />
        <input type="submit" value="Send" />
      </form>
    </div>
  );
};

export default Contact;
