import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './Header.module.css';

const Header = () => {
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(true);

  return (
    <div className={classes.container}>
      <div className={classes.logo}>RS</div>
      <div
        className={classes.burger}
        onClick={() => setOpen(!isOpen)}
        aria-hidden="true"
      >
        <div />
        <div />
        <div />
      </div>
      <div className={isOpen ? classes.nav : classes.navmobile}>
        <Link className={pathname === '/' ? classes.active : ''} to="/">
          Home
        </Link>
        <Link
          className={pathname === '/about' ? classes.active : ''}
          to="/about"
        >
          About Me
        </Link>
        <Link
          className={pathname === '/contact' ? classes.active : ''}
          to="/contact"
        >
          Contact Me
        </Link>
      </div>
    </div>
  );
};

export default Header;
