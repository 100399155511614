// prettier-ignore
import { Link } from 'react-router-dom';
import beforeDawn from './assets/undraw_before_dawn_re_hp4m.svg';
import classes from './Home.module.css';

const Home = () => (
  <div className={classes.container}>
    <div className={classes.info}>
      <h1> Hi ! I'm</h1>
      <h1> Md Abu Talha</h1>
      <p> Electrical & Electronic Engineer .</p>
      <div className={classes.button}>
        {/* <div className={classes.subbutton}>
          <a href="./assets/MdAbuTalha.pdf"> Get CV </a>
        </div> */}
        <div className={classes.subbutton}>
          <Link to="/contact"> Contact Me </Link>
        </div>
      </div>
    </div>
    <img src={beforeDawn} alt="beforeDawn" />
  </div>
);

export default Home;
